import React, { useEffect } from 'react';
import { useLocation,useNavigate,Link } from 'react-router-dom';

import { Row, Col, Form, Button, Image, Nav,Alert, Spinner } from 'react-bootstrap';

import { Helmet } from 'react-helmet-async';
import GoogleLoginComponent from '../components/GoogleLoginComponent_2';


import { MUZAIC_ROOT } from '../config'; // Importing the constant
import { MUZAIC_API_ROOT } from '../config'; // Importing the constant


const Login = () => {

	const [emailOrUsername, setEmailOrUsername] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [keepMeLoggedIn, setKeepMeLoggedIn] = React.useState(true);
	const [errors, setErrors] = React.useState({});
	const [isLoading, setIsLoading] = React.useState(false);
  	const [serverError, setServerError] = React.useState('');
  	const [canvaDetails, setCanvaDetails] = React.useState(false);
	
	const location = useLocation();
	const navigate= useNavigate();
	
	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const error = urlParams.get('error');
		const canvaLogin = urlParams.get('canva_user_token');
		if (error) {
		  setServerError(error);
		};
		if (canvaLogin) {
		  setCanvaDetails({'canva_user_token':canvaLogin,'state':urlParams.get('state'),'nonce':urlParams.get('nonce')});
		}
	  }, [location]);
	
	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors({});
		setServerError('');
		setIsLoading(true);
		
		const newErrors = {};

		  if (!emailOrUsername) {
			newErrors.emailorusername = 'Email or username is required';
		  }
		
		  if (!password) {
			newErrors.password = 'Password is required';
		  }
		
		  // If there are errors, set them and stop the submission process
		  if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			setIsLoading(false);
			return;
		  }
	
		try {
		  const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_login.php`, {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({ emailOrUsername, password,keepMeLoggedIn }),
		  });
	
		 const data = await response.json();
		//	 const data = await response.text();
		//console.log(data);
		if (response.ok) {
			if (data.accessToken) {
				if (keepMeLoggedIn && data.refreshToken) {
				  localStorage.setItem('muzaic_refresh_token', data.refreshToken);
				}
			  sessionStorage.setItem('muzaic_access_token', data.accessToken);
			  // Redirect to the dashboard or another page
			  if(!canvaDetails.state) navigate ('/dashboard');
          		else navigate(`/purchase-only?state=${canvaDetails.state}`);
			} else {
			  // Handle the case where response is ok but token is not present
			  setServerError('Unexpected response format. Please try again.');
			}
		  } else {
		  	console.log(data.message);
			setServerError(data.message); 
		  }
		} catch (error) {
		  setServerError('An error occurred. Please try again later.');
		} finally {
		  setIsLoading(false);
		}
	  };



  return (
  	<>
		<Helmet>
			<title>Login – Muzaic</title>
		</Helmet>

		 
				<Row className="justify-content-center g-2 mb-4">
				  <Col md={5} xs={10} className="d-flex justify-content-center align-items-center py-3">
					<Image src={`${MUZAIC_ROOT}img/muzaic_logo.svg`} alt="Company Logo" className="logo_text" fluid />
				  </Col>
				</Row>
				<Row className="g-2 p-2">
				  <Col>
					<Form noValidate onSubmit={handleSubmit}>
					  <Form.Group className="mb-3" controlId="EmailOrUsername">
						<Form.Control type="text" 
							className="form-control-lg bg-white font-small" 
							placeholder="Enter email or username" 
							onChange={(e) => setEmailOrUsername(e.target.value)}
							isInvalid={!!errors.emailorusername}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.emailorusername}
						</Form.Control.Feedback>
					  </Form.Group>
	
					  <Form.Group className="mb-3" controlId="Password">
						<Form.Control type="password" 
							className="form-control-lg bg-white font-small" 
							placeholder="Password" 
							onChange={(e) => setPassword(e.target.value)}
							isInvalid={!!errors.password}
						/>
						<Form.Control.Feedback type="invalid">
						  {errors.password}
						</Form.Control.Feedback>
						<div className="mt-2 w-100 text-end">
						  <Link to="/forgot-password" className="small text-muted">
							Forgot Password?
						  </Link>
						</div>
						
					  </Form.Group>
					  { !canvaDetails.state ? (
					  <Form.Group controlId="keepMeLoggedIn" className="mt-3 ps-2">
						<Form.Check
						  type="checkbox"
						  label="Keep me logged in"
						  checked={keepMeLoggedIn}
						  onChange={(e) => setKeepMeLoggedIn(e.target.checked)}
						/>
					  </Form.Group>
						) : null
						}
					  <Button variant="primary" type="submit" className="btn-lg w-100 mt-2 font-small" disabled={isLoading}>
							{isLoading ? <Spinner animation="border" size="sm" /> : 'Login'}
					  </Button>
					 
						{/*<GoogleLoginComponent setServerError={setServerError} keepMeLoggedIn={keepMeLoggedIn} canvaState={canvaDetails.state}/>*/}
						
					  {serverError && <Alert variant="danger" className="mt-3">{serverError}</Alert>}
					</Form>
					
				  </Col>
				</Row>
				<Row className="justify-content-center g-2 mt-5 w-100">
					<Col className="text-center">
						<span>Don't have an account? <Link to={`/signup${canvaDetails ? `?state=${canvaDetails.state}&canva_user_token=${canvaDetails.canva_user_token}&nonce=${canvaDetails.nonce}` : ''}`}  className="inline-link nav-link">Sign up</Link></span>
					</Col>
				  </Row>
			  
		  {/*<Row className="justify-content-center g-2 mt-3 w-100">
			<Col xs={12} md={7} lg={5} className="text-center">
			  <div className="auth-footer pt-3">
				<span>Don't have an account? <a href="/signup">Sign up</a></span>
			  </div>
			</Col>
		  </Row>
		  */}

    </>
  );
};

export default Login;
