import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Table, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { MUZAIC_API_ROOT } from '../config';

const Dashboard = () => {
	const [profile, setProfile] = useState({ daysActive: "-", callsMade: "-", overallUsage:"-",tokens:"-",canvaId:'' });
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_dashboardDetails.php`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }

        const data = await response.json();
        
        setProfile({ daysActive: data.daysActive, callsMade: data.callsMade, overallUsage:data.overall_usage, tokens: data.tokens,canvaId: data.canvaId });
        setLoadingProfile(false);
      } catch (error) {
        setError(error.message);
        setLoadingProfile(false);
      }
    };

    fetchProfile();
  }, []);


  return (
  	<>
		<Helmet>
			<title>Dashboard – Muzaic</title>
		</Helmet> 	
		<div>
				  <h1>Dashboard</h1>
			  <p>Welcome to Muzaic!</p>
		</div>
        {error && <Alert variant="danger">{error}</Alert>}
          <Row className="text-center g-3">
          <Col md={3}>
          	<div className="d-flex flex-column justify-content-center align-items-center border rounded p-4">
          		<h1>{loadingProfile ? "-" : profile.daysActive}</h1>
          		<span>Days active</span>
          	</div>
          </Col>
          <Col md={3}>
          	<div className="d-flex flex-column justify-content-center align-items-center border rounded p-4">
          		<h1>{loadingProfile ? "-" : profile.callsMade}</h1>
          		<span>Total calls made</span>
          	</div>
          </Col>
          <Col md={3}>
          	<div className="d-flex flex-column justify-content-center align-items-center border rounded p-4">
          		<h1>{loadingProfile ? "-" : profile.overallUsage}</h1>
          		<span>Total tokens used</span>
          	</div>
          </Col>
          <Col md={3}>
          	<div className="d-flex flex-column justify-content-center align-items-center border rounded p-4">
          		<h1>{loadingProfile ? "-" : profile.tokens}</h1>
          		<span>Tokens available</span>
          	</div>
          </Col>
          </Row>
	</>
  );
};

export default Dashboard;
