import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Table, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { loadStripe } from '@stripe/stripe-js';
import { MUZAIC_API_ROOT, STRIPE_PUBLIC_KEY } from '../config';

const Profile = () => {
  const [profile, setProfile] = useState({ name: '', email: '', canvaId:'' });
  const [apiKeys, setApiKeys] = useState([]);
    const [defaultKeyId, setDefaultKeyId] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingApiKeys, setLoadingApiKeys] = useState(true);
const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_userDetails.php`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }

        const data = await response.json();

        setProfile({ name: data.name, email: data.email, canvaId: data.canvaId });
        setLoadingProfile(false);
      } catch (error) {
        setError(error.message);
        setLoadingProfile(false);
      }
    };

    const fetchApiKeys = async () => {
      try {
        const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_getApiKeys.php`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch API keys');
        }

        const data = await response.json();

        setApiKeys(data);
         // Set the default API key ID
        const defaultKey = data.find((key) => key.isDefault=="1");
        setDefaultKeyId(defaultKey ? defaultKey.id : null);

        setLoadingApiKeys(false);
      } catch (error) {
        setError(error.message);
        setLoadingApiKeys(false);
      }
    };

    fetchProfile();
    fetchApiKeys();
  }, []);

	const handleDefaultChange = async (selectedKeyId) => {
    setDefaultKeyId(selectedKeyId);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_setDefaultApiKey.php`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ apiKeyId: selectedKeyId }),
      });

      if (!response.ok) {
        throw new Error('Failed to update default API key');
      }

      setSuccess('Default API key updated successfully');
    } catch (error) {
      setError(error.message);
    }
  };
  
  const handleUpgradeClick = async (keyId) => {

    const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_stripeSession.php`, {
      method: 'POST',
      headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ keyId }),
    });

    const session = await response.json();
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (error) {
      console.error('Error:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_updateUserDetails.php`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      setSuccess('Profile updated successfully');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return null;
    if (sortConfig.direction === 'asc') return ' 🔼';
    return ' 🔽';
  };

  return (
    <>
      <Helmet>
        <title>Profile – Muzaic</title>
      </Helmet>
      <div>
      <h1>My Profile</h1>
        <p>This is your Muzaic account details.</p>
      	<div className="mt-5 mb-3">
        <h5>Personal Information</h5>
        {loadingProfile && <p>Loading...</p>}
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        {!loadingProfile && (
          <Form onSubmit={handleSubmit} className="mb-3 font-small">
          	<Row>
          	<Col md={6}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                className="bg-white"
                type="text"
                name="name"
                value={profile.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="bg-white"
                type="email"
                name="email"
                value={profile.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group controlId="canvaId" className="mb-3">
              <Form.Label>Canva Id</Form.Label>
              <Form.Control
                type="text"
                name="canvaId"
                value={profile.canvaId || "Account not connected to Canva"}
				disabled
              />
            </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group controlId="adobeId" className="mb-3">
              <Form.Label>Adobe Id</Form.Label>
              <Form.Control
                type="text"
                name="adobeId"
                value={profile.adobeId || "Account not connected to Adobe"}
				disabled
              />
            </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Updating...' : 'Update Profile'}
            </Button>
            </Col>
            </Row>
          </Form>
        )}
		</div>
		
      </div>
    </>
  );
};

export default Profile;
