import React, {useState} from 'react';
import { Table, Button, Form, Alert,Spinner, Modal } from 'react-bootstrap';
import { MUZAIC_API_ROOT } from '../config';

const ApiKeys = ({ apiKeys, defaultApiKey, setDefaultApiKey, activeApiKey, setActiveApiKey, refreshApiKeys }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
	  const [isAdding, setIsAdding] = useState(false);  
	  const [showModal, setShowModal] = useState(false);

  const handleDefaultChange = async (apiKey) => {
    setError(null);
    setSuccess(null);
    
    try {
      const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_setDefaultApiKey.php`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ apiKeyId: apiKey.id }),
      });
      if (!response.ok) throw new Error('Failed to update default API key');
		let	data=await response.json();
      setSuccess(data.message);
      setDefaultApiKey(apiKey); // Set the full API key object as default
      refreshApiKeys(); // Trigger re-fetch of API keys
    } catch (err) {
      setError(err.message);
    }
  };

	const addNewKey = async () => {
		try {
		  const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_addNewApiKey.php`, {
			method: 'POST',
			headers: {
			  'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify(),
		  });
		  if (!response.ok) throw new Error('Failed to add new API key');
		let	data=await response.json();
		  setSuccess(data.message);
		  refreshApiKeys(); // Trigger re-fetch of API keys
		} catch (err) {
		  setError(err.message);
		}
		setShowModal(false);
	};
	
	const cancelAddNewKey =() => {
		setShowModal(false);
		setIsAdding(false);
	};

  return (
    <div className="mt-5 mb-3">
      <h5>API Keys</h5>
      {error && <Alert variant="danger">{error}</Alert>}

      <div className="table-responsive">
        <Table className="shadow-sm no-accent-bg">
          <thead>
            <tr>
              <th>Created at</th>
              <th>Valid through</th>
              <th>API Key</th>
              <th>Key type</th>
              <th>Tokens available</th>
              <th>Is default</th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.map((key) => (
              <tr key={key.id} onClick={() => setActiveApiKey(key)} className={activeApiKey && activeApiKey.id === key.id ? 'active-key-row' : 'clickable'}>
                <td>{new Date(key.createdAt).toLocaleString()}</td>
                <td>{key.validThrough ? new Date(key.validThrough).toLocaleString() : "---"}</td>
                <td>{key.value}</td>
                <td>{key.name}</td>
                <td>{key.tokens || 'No tokens'}</td>
                <td className="text-center">
                  <Form.Check
                    className="clickable"
                    type="radio" 
                    checked={key.id === defaultApiKey?.id}
                    onChange={() => handleDefaultChange(key)} 
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {success && <Alert variant="success">{success}</Alert>}
        <Button 
			  onClick={() => setShowModal(true)}
			  disabled={isAdding}
			>
			  {isAdding ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Add new'}
			</Button>
      </div>
      
      <Modal show={showModal} onHide={cancelAddNewKey}>
      <Modal.Header closeButton>
        <Modal.Title>New Api Key</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to add a new API key to your account?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={cancelAddNewKey}>
          Cancel
        </Button>
        <Button variant="info" onClick={addNewKey}>
          Add API Key
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
    
  );
};

export default ApiKeys;
