import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';


const AuthLayout = ({ children }) => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
		 <Row className="justify-content-center g-2 w-100">
			<Col xs={12} md={7} lg={5}>
			  <div className="auth-container p-4 bg-white rounded">
      {children}
          	<Outlet/>
          	</div>
			</Col>
		  </Row>
    </Container>
  );
};

export default AuthLayout;
