import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import useTokenRefresh from './useTokenRefresh';

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useTokenRefresh(); // Invoke the token refresh logic

  useEffect(() => {
    const checkToken = () => {
      const token = sessionStorage.getItem('muzaic_access_token');
	
      if (!token) {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          sessionStorage.removeItem('muzaic_access_token');
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }

      setIsLoading(false);
    };

    checkToken();
  }, []);

  if (isLoading) {
  return <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>;
}

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
