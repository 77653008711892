import React, { useEffect, useState } from 'react';
import { Alert, Spinner,Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useLocation,useNavigate } from 'react-router-dom';
import { MUZAIC_API_ROOT } from '../config';

const PurchaseResponse = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('info');
  const [canvaState,setCanvaState] = useState(false);

	const navigate= useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');

    if (sessionId) {
      // Handle the success scenario by verifying payment
      const verifyPayment = async () => {
        try {
        const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_stripeVerifyPayment.php?session_id=${sessionId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
            'Content-Type': 'application/json',
          },
        });
        
          if (!response.ok) {
            throw new Error('Payment verification failed');
          }
          
          const data = await response.json();
          setCanvaState(data.canvaState);
          setMessage('Thank you! Your purchase was successful and tokens have been added to your account.');
          setVariant('success');
        } catch (error) {
          setMessage(error.message);
          setVariant('danger');
        } finally {
          setLoading(false);
        }
      };
      verifyPayment();
    } else {
      // Handle the cancel scenario
      setMessage('It seems you have canceled the payment. You can try again if this was a mistake.');
      setVariant('warning');
      setLoading(false);
    }
  }, [location.search]);

  return (
  	<>
      <Helmet>
        <title>Purchase – Muzaic</title>
      </Helmet>
      <div className="bg-white">
        <div className='mb-4'><h1 className='text-center'>Your purchase summary</h1></div>
        {loading && <Spinner animation="border" />}
      	<Alert variant={variant}>{message}</Alert>
      	<Button variant="primary" className="btn-lg w-100 mt-2 font-small" onClick={()=>{
      			canvaState ? window.location.href=`https://www.canva.com/apps/configured?success=true&state=${canvaState}` : navigate('/purchase');
      			}}>
              Continue to {canvaState ? 'Canva' : 'Muzaic'}
            </Button>
      </div>
     
    </>

  );
};

export default PurchaseResponse;
