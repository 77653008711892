import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Alert, Spinner,Badge } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { MUZAIC_API_ROOT, STRIPE_PUBLIC_KEY } from '../config';
import DowngradeConfirmationModal from './DowngradeConfirmationModal';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Products = ({ activeApiKey, refreshApiKeys }) => {
  const [success, setSuccess] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loadingButtons, setLoadingButtons] = useState(true);


  useEffect(() => {
    // Fetch the products when the component mounts
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_products.php`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Failed to fetch products');
        const data = await response.json();
        setProducts(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
        setLoadingButtons(false);
      }
    };
    fetchProducts();
  }, []); // Only on initial mount

  const handleProductAction = async (product, apiKey) => {
	  setLoadingButtons(true); // Set loading state to true for all buttons
	
	const priceId=product.stripePriceId;
	const keyTypeId=product.apiKeyTypeId;
	  const keyId = apiKey.id;
	  const subscriptionId=apiKey.subscriptionId;
	  if (!subscriptionId || product.tier>activeApiKey.tier) {
		// New purchase or subscription upgrade
		const urlParams = new URLSearchParams(location.search);
		const canvaState = urlParams.get('state');
	
		try {
		  const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_stripeSession.php`, {
			method: 'POST',
			headers: {
			  'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({ priceId, canvaState, keyId, keyTypeId,subscriptionId }),
		  });
	
		  const session = await response.json();
	
		  if (session.id) {
			const stripe = await stripePromise;
			const { error } = await stripe.redirectToCheckout({
			  sessionId: session.id,
			});
	
			if (error) {
			  console.error('Error:', error);
			}
		  } else {
			console.error('Session ID missing from response');
		  }
		} catch (error) {
		  console.error('Error:', error);
		} finally {
		  setLoadingButtons(false); // Clear loading state for all buttons
		}
	  } else {
		// Update existing subscription (downgrade)
		try {
		  const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_stripeDowngradeSubscription.php`, {
			method: 'POST',
			headers: {
			  'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({ subscriptionId, priceId, keyId, keyTypeId }),
		  });
	
		  const result = await response.json();
		  if (result.error) {
			console.error('Error updating subscription:', result.error);
		  } else {
			setSuccess(result.message);
			refreshApiKeys(); // Trigger to update API keys
		  }
		} catch (error) {
		  console.error('Error:', error);
		} finally {
		  setLoadingButtons(false); // Clear loading state for all buttons
		} 
	  }
	};


  const handleConfirmDowngrade = () => {
    if (selectedProduct) {
      handleProductAction(
        selectedProduct,
        activeApiKey
      );
      setShowModal(false); // Close modal after confirming
    }
  };

  const handleDowngradeClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true); // Open the modal for confirmation
  };

 const getButton = (product, apiKey) => {
	  const isLoading = loadingButtons; // Global loading state
	
	  if (!apiKey) {
		return (
		  <Button 
			onClick={() => handleProductAction(product, apiKey)}
			disabled={isLoading}
		  >
			{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Buy'}
		  </Button>
		);
	  }
	
	  if (product.apiKeyTypeId === apiKey.typeId) {
		if (product.apiKeyTypeId !== "1") {
		  return (
			<Button 
			  variant="secondary" 
			  onClick={() => handleProductAction(product, apiKey)}
			  disabled={isLoading}
			>
			  {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Manage'}
			</Button>
		  );
		} else {
		  return <Button variant="secondary" disabled>Your plan</Button>;
		}
	  }
	
	  if (product.tier && apiKey.tier) {
		const productTier = parseInt(product.tier);
		const defaultTier = parseInt(apiKey.tier);
	
		if (productTier < defaultTier) {
		  return (
			<Button 
			  variant="secondary" 
			  onClick={() => handleDowngradeClick(product)}
			  disabled={isLoading}
			>
			  {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Downgrade'}
			</Button>
		  );
		} else {
		  return (
			<Button 
			  onClick={() => handleProductAction(product, apiKey)}
			  disabled={isLoading}
			>
			  {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Upgrade'}
			</Button>
		  );
		}
	  }
	
	  return (
		<Button 
		  onClick={() => handleProductAction(product, apiKey)}
		  disabled={isLoading}
		>
		  {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Buy'}
		</Button>
	  );
	};

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Row className="text-center g-3">
        {loading ? (
          <div>Loading...</div>
        ) : (
          products.map((product) => (
            <Col xxl={3} xl={4} lg={4} md={6} sm={12} xs={12} key={product.id} className="d-flex">
              <div className={`d-flex flex-column h-100 w-100 justify-content-between border rounded p-4 bg-white shadow ${activeApiKey && product.apiKeyTypeId === activeApiKey.typeId ? 'border-purple' : ''}`}>
              	
              	{activeApiKey && product.apiKeyTypeId === activeApiKey.typeId && activeApiKey.changes==="1" ? (
              		<div style={{marginTop:'-38px',marginBottom:'16px'}}>
	              		<Badge bg='info'>Subscription ends at {activeApiKey.validThrough}</Badge>
	              	 </div>
              		) : null}

                <div className="text-center d-flex flex-column" style={{ height: '200px' }}>
                  <div className="flex-grow-1">
                    <h3 className="mb-0">{product.name}</h3>
                  </div>
                  <div className="flex-shrink">
                    <h1 className="mb-0 text-large">${(product.price / 1).toFixed(2)}</h1>
                    <span className="text-small text-muted mt-n1">per month</span>
                  </div>
                </div>
                <div className="m-3 flex-grow-1 text-center">
                  {product.description}
                </div>
                <div className="mt-auto text-center">
                  {getButton(product, activeApiKey)}
                </div>
                
              </div>
            </Col>
          ))
        )}
      </Row>
      <DowngradeConfirmationModal
        show={showModal}
        product={selectedProduct}
        onHide={() => setShowModal(false)}
        onConfirm={handleConfirmDowngrade}
      />
    </>
  );
};

export default Products;
