import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';



const LeftMenu = () => {
  return (
    <Nav className="flex-column left-menu ">
      <Nav.Item>
        <Link to="/dashboard" className="nav-link rounded">
          <i className="bi bi-house"></i>
          <span className="d-none d-md-inline ms-2">Dashboard</span>
        </Link>
      </Nav.Item>
    <Nav.Item>
        <Link to="/purchase" className="nav-link rounded">
          <i className="bi bi-wallet2"></i>
          <span className="d-none d-md-inline ms-2">Purchase</span>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/mymusic" className="nav-link rounded">
          <i className="bi bi-music-note-list"></i>
          <span className="d-none d-md-inline ms-2">My Music</span>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/stats" className="nav-link rounded">
          <i className="bi bi-bar-chart-line"></i>
          <span className="d-none d-md-inline ms-2">Stats</span>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/profile" className="nav-link rounded">
          <i className="bi bi-person"></i>
          <span className="d-none d-md-inline ms-2">Profile</span>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/help" className="nav-link rounded">
          <i className="bi bi-question-circle"></i>
          <span className="d-none d-md-inline ms-2">Help</span>
        </Link>
      </Nav.Item>
      <Nav.Item className="mt-auto">
        <Link to="/logout" className="nav-link rounded">
          <i className="bi bi-box-arrow-right"></i>
          <span className="d-none d-md-inline ms-2">Logout</span>
        </Link>
      </Nav.Item>
    </Nav>
  );
};

export default LeftMenu;
