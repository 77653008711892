import React, { createContext, useContext, useState, useMemo } from 'react';

// Define the context type
type CanvaAuthContextType = {
  canvaAuthenticated: Record<string, any> | null;
  setCanvaAuthenticated: (data: Record<string, any> | null) => void;
  token: string | null;
  setToken: (token: string | null) => void;
};

// Create the context
const CanvaAuthContext = createContext<CanvaAuthContextType | undefined>(undefined);

// Provider Component
export const CanvaAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [canvaAuthenticated, setCanvaAuthenticated] = useState<Record<string, any> | null>(null);
  const [token, setToken] = useState<string | null>(null);

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(
    () => ({ canvaAuthenticated, setCanvaAuthenticated, token, setToken }),
    [canvaAuthenticated, token]
  );

  return <CanvaAuthContext.Provider value={value}>{children}</CanvaAuthContext.Provider>;
};

// Hook to use the CanvaAuthContext
export const useCanvaAuth = (): CanvaAuthContextType => {
  const context = useContext(CanvaAuthContext);
  if (context === undefined) {
    throw new Error('useCanvaAuth must be used within a CanvaAuthProvider');
  }
  return context;
};
