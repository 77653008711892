import React from 'react';
import { Row, Col, Form, Button, Image, Alert, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { MUZAIC_ROOT } from '../config'; // Importing the constant
import { MUZAIC_API_ROOT } from '../config'; // Importing the constant

const ForgotPassword = () => {
  const [email, setEmail] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [serverMessage, setServerMessage] = React.useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setServerMessage('');
    setIsLoading(true);

    if (!email) {
      setErrors({ email: 'Email is required' });
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_passResetRequest.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        setServerMessage('If an account with that email exists, you will receive a password reset email shortly.');
      } else {
        setServerMessage(data.message || 'An error occurred. Please try again later.');
      }
    } catch (error) {
      setServerMessage('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password – Muzaic</title>
      </Helmet>

      <Row className="justify-content-center g-2 mb-4">
        <Col md={5} xs={10} className="d-flex justify-content-center align-items-center py-3">
          <Image src={`${MUZAIC_ROOT}img/muzaic_logo.svg`} alt="Company Logo" className="logo_text" fluid />
        </Col>
      </Row>
      <Row className="g-2 p-2">
        <Col>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="Email">
              <Form.Control
                type="email"
                className="form-control-lg bg-white font-small"
                placeholder="Enter your email address"
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" className="btn-lg w-100 mt-2 font-small" disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Reset Password'}
            </Button>
            {serverMessage && <Alert variant="info" className="mt-3">{serverMessage}</Alert>}
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center g-2 mt-5 w-100">
        <Col className="text-center">
          <span>Remember your password? <Link to="/login" className="inline-link nav-link">Login</Link></span>
        </Col>
      </Row>
    </>
  );
};

export default ForgotPassword;
