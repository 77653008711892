import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Products from '../components/Products';
import ApiKeys from '../components/ApiKeys';
import { MUZAIC_API_ROOT } from '../config';

const Purchase = ({ simpleView }) => {
  const [apiKeys, setApiKeys] = useState([]);
  const [defaultApiKey, setDefaultApiKey] = useState(null);
  const [activeApiKey, setActiveApiKey] = useState(null);
  
  // Function to fetch API Keys
  const fetchApiKeys = async () => {
    try {
      const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_getApiKeys.php`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch API keys');
      const data = await response.json();
      setApiKeys(data);

      // Find and set the default API key object
      const defaultKey = data.find((key) => key.isDefault === "1");
      setDefaultApiKey(defaultKey || null);
      setActiveApiKey(defaultKey || null);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    // Fetch API keys on mount
    fetchApiKeys();
  }, []);

  return (
    <>
      <Helmet>
        <title>Purchase – Muzaic</title>
      </Helmet>
      {!simpleView && <h1>Subscriptions</h1>}
      
      {/* Pass relevant props and functions to child components */}
      <Products activeApiKey={activeApiKey} refreshApiKeys={fetchApiKeys} />
      <ApiKeys 
        apiKeys={apiKeys} 
        defaultApiKey={defaultApiKey} 
        setDefaultApiKey={setDefaultApiKey} 
        activeApiKey={activeApiKey} 
        setActiveApiKey={setActiveApiKey}
        refreshApiKeys={fetchApiKeys}
      />
    </>
  );
};

export default Purchase;
