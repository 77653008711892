import React, { useState, useEffect } from 'react';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useLocation} from 'react-router-dom';


const CanvaBack = () => {
	
	const location=useLocation();
	
	const [error, setError] = useState(null);
  
	useEffect(() => {
    // Parse query parameters from the location object
    const queryParams = new URLSearchParams(location.search);
    const errorParam = queryParams.get('error');

    // Check if the error parameter is set to 12
    if (errorParam === '12') {
      setError('Your session has expired. Please go back to Canva and restart.');
    } else {
      setError(null);
    }
  }, [location.search]); // Re-run the effect if the search part of the URL changes


  return (
    <>
      <Helmet>
        <title>AI Music</title>
      </Helmet>
      
 
      <Row className="p-2">
      {error && <Alert variant="danger">{error}</Alert>}     
      <Button onClick={()=>window.close()} variant="primary">Back to Canva</Button>
      </Row>
    </>
  );
};

export default CanvaBack;
