import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header';
import LeftMenu from '../components/LeftMenu';
import { Outlet } from 'react-router-dom';
import useTokenRefresh from '../components/useTokenRefresh';

const MainLayout = () => {
  return (
    <Container fluid className="app-container">
      <Header />
      <Row className="">
        <Col xs={2} md={3} lg={2}>
          <LeftMenu />
        </Col>
        <Col xs={10} md={9} lg={10}>
        	<div style={{paddingLeft:'1rem', maxHeight: '83vh',overflowX:'visible' }}>
			  <Row className="main-container pe-3">
				<Col xs={12} md={12}>
					<Outlet /> {/* This is where the nested routes will be rendered */}
				</Col>
			  </Row>
			</div>
        </Col>
      </Row>
    </Container>
  );
};

export default MainLayout;
