import React from 'react';
import { Helmet } from 'react-helmet-async';

const Help = () => {
  return (
  	<>
		<Helmet>
			<title>Help – Muzaic</title>
		</Helmet> 
		<div>
		  <h1>Help</h1>
		  <p>This is the Help page.</p>
		</div>
	</>
  );
};

export default Help;
