import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = ({ onLogout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear tokens
    sessionStorage.removeItem('muzaic_access_token');
    localStorage.removeItem('muzaic_refresh_token');

    // Execute any additional logout actions passed in as props
    if (onLogout) {
      onLogout();
    }

    // Redirect to the login page
    navigate('/login');
  }, [navigate, onLogout]);

  return null; // No need to render anything
};

export default Logout;
