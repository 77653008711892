import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { MUZAIC_ROOT } from '../config';  // Import the constant

const Header = () => {
  return (
    <Row className="g-2 mb-3">
		  <Col xs ={2} md={2} className="d-flex justify-content-start ps-4 align-items-center">
			<Image src={`${MUZAIC_ROOT}img/muzaic_logo.svg`} alt="Company Logo" className="logo_text" />
		  </Col>
		  <Col xs={10} md={10} className="d-flex justify-content-end align-items-center pe-3">
			<i className="bi bi-gear"></i>
		  </Col>
    </Row>
  );
};

export default Header;
