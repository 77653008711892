import { useEffect, useRef, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { MUZAIC_API_ROOT } from '../config';
import Logout from './Logout';

const useTokenRefresh = () => {
  const [logout, setLogout] = useState(false); // State to trigger logout
  const timeoutIdRef = useRef(null); // Store the timeout ID

  const token = sessionStorage.getItem('muzaic_access_token');
  const refreshToken = localStorage.getItem('muzaic_refresh_token');

  useEffect(() => {
    // Only proceed if both access token and refresh token are present
    if (!token || token === 'undefined') {
      if (!refreshToken) {
        return; // No token and no refresh token, do nothing
      }
    }

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        const timeoutDuration = (decodedToken.exp - currentTime - 60) * 1000;

        // Check if the access token is about to expire
        if (timeoutDuration > 0) {
          // Set up a timeout to refresh the token when it's about to expire
          if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
          }
          timeoutIdRef.current = setTimeout(() => {
            refreshAccessToken();
          }, timeoutDuration);
        } else {
          // Token is already expired, refresh immediately
          refreshAccessToken();
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        triggerLogout();
      }
    } else if (refreshToken) {
      // If there's no valid access token but there's a refresh token, refresh the token immediately
      refreshAccessToken();
    }

    return () => clearTimeout(timeoutIdRef.current);
  }, [token, refreshToken]);

  const refreshAccessToken = async () => {
    if (!refreshToken) {
      // If there's no refresh token, trigger logout
      triggerLogout();
      return;
    }

    try {
      const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_refreshToken.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken }),
      });

      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem('muzaic_access_token', data.accessToken);

        if (data.refreshToken) {
          localStorage.setItem('muzaic_refresh_token', data.refreshToken);
        }

        const decodedToken = jwtDecode(data.accessToken);
        const newTimeoutDuration = (decodedToken.exp - Date.now() / 1000 - 60) * 1000;

        // Set a timeout to refresh the token again before it expires
        timeoutIdRef.current = setTimeout(() => refreshAccessToken(), newTimeoutDuration);
      } else {
        triggerLogout();
      }
    } catch (error) {
      console.error('Failed to refresh access token:', error);
      triggerLogout();
    }
  };

  const triggerLogout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    setLogout(true);
  };

  if (logout) {
    return <Logout onLogout={() => setLogout(false)} />;
  }

  return null;
};

export default useTokenRefresh;
