// DowngradeConfirmationModal.js

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DowngradeConfirmationModal = ({ show, product, onHide, onConfirm }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm downgrade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to downgrade to {product?.name}? You may lose access to some features.
      </Modal.Body>
      <Modal.Footer>
      	<Button variant="danger" className="w-100" onClick={() => onConfirm(product)}>
          Confirm downgrade
        </Button>
        <Button variant="secondary" className="w-100" onClick={onHide}>
          Cancel
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
};

export default DowngradeConfirmationModal;
