import React, { useEffect, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useCanvaAuth } from '../contexts/CanvaAuthContext2.tsx';

const CanvaRoute = ({ children }: { children: React.ReactNode }) => {
  const { canvaAuthenticated, setCanvaAuthenticated,setToken } = useCanvaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const loginCanvaUser = async (token: string) => {
    try {
      const tokenResponse = await fetch("https://api.muzaic.ai/canvaUserLogin", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!tokenResponse.ok) {
        throw new Error(`HTTP error! status: ${tokenResponse.status}`);
      }

      const data = await tokenResponse.json();
      return data; // Assuming this is the Canva auth details object
    } catch (error) {
      console.error("Error during login:", error);
      return null;
    }
  };

  useEffect(() => {
    const login = async () => {
      const token = searchParams.get('token');
      if (!token) {
        console.error("No token provided in URL");
        setCanvaAuthenticated(null);
        setIsLoading(false);
        return;
      }

      const authData = await loginCanvaUser(token);
      setCanvaAuthenticated(authData);
      setToken(token);
      setIsLoading(false);
    };

    login();
  }, [searchParams, setCanvaAuthenticated]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return canvaAuthenticated ? <>{children}</> : <Navigate to="/canva-back?error=12" />;
};

export default CanvaRoute;
