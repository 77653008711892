import React, { useState, useEffect } from 'react';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useLocation} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { MUZAIC_API_ROOT, STRIPE_PUBLIC_KEY } from '../config';

// Load the publishable key from your environment
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Purchase = () => {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [error, setError] = useState(null);

const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_products.php`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }

        const data = await response.json();
        
        setProducts(data);
        setLoadingProducts(false);
      } catch (error) {
        setError(error.message);
        setLoadingProducts(false);
      }
    };

    fetchProducts();
  }, []);

  const handleClick = async (priceId) => {
  	const urlParams = new URLSearchParams(location.search);
		const canvaState = urlParams.get('state'); 
    const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_stripeSession.php`, {
      method: 'POST',
      headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ priceId,canvaState }),
    });

    const session = await response.json();
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Purchase – Muzaic</title>
      </Helmet>
      
      {error && <Alert variant="danger">{error}</Alert>}
      <Row className="text-center g-3">
        {loadingProducts ? (
          <div>Loading...</div>
        ) : (
          products.map((product) => (
            <Col md={6} xs={6} key={product.id}>
              <div className="d-flex flex-column justify-content-center align-items-center border rounded p-4 bg-white">
              	 <h3>{product.name}</h3>
                <h1 className="m-2 text-large">${(product.price/1).toFixed(2)}</h1>
                <span className="text-small text-muted mt-n1">per month</span>
                <span className="m-2">{product.description}</span>
                <Button onClick={() => handleClick(product.stripePriceId)}> Buy </Button>
              </div>
            </Col>
          ))
        )}
      </Row>
    </>
  );
};

const PurchasePage = () => {
  return (
    <Elements stripe={stripePromise}>
      <Purchase />
    </Elements>
  );
};

export default PurchasePage;
