import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Alert, Image, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { MUZAIC_ROOT } from '../config'; // Importing the constant

import { MUZAIC_API_ROOT } from '../config'; // Importing the constant

const PasswordReset = () => {
	const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token'); // Extract the token from query string
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [serverMessage, setServerMessage] = React.useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setServerMessage('');
    setIsLoading(true);

    const newErrors = {};

    if (!newPassword) {
      newErrors.newPassword = 'New password is required';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'Please confirm your new password';
    } else if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    try {
    	console.log(token);
      const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_passReset.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword }),
      });

      //const data = await response.json();
      const data = await response.text();
      console.log(data);
      if (response.ok) {
        setServerMessage('Your password has been reset successfully. You can now log in with your new password.');
      } else {
        setServerMessage(data.message || 'An error occurred. Please try again later.');
      }
    } catch (error) {
      setServerMessage('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Password Reset – Muzaic</title>
      </Helmet>

      <Row className="justify-content-center g-2 mb-4">
        <Col md={5} xs={10} className="d-flex justify-content-center align-items-center py-3">
          <Image src={`${MUZAIC_ROOT}img/muzaic_logo.svg`} alt="Company Logo" className="logo_text" fluid />
        </Col>
      </Row>
      <Row className="g-2 p-2">
        <Col>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="NewPassword">
              <Form.Control
                type="password"
                className="form-control-lg bg-white font-small"
                placeholder="Enter new password"
                onChange={(e) => setNewPassword(e.target.value)}
                isInvalid={!!errors.newPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="ConfirmPassword">
              <Form.Control
                type="password"
                className="form-control-lg bg-white font-small"
                placeholder="Confirm new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                isInvalid={!!errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" className="btn-lg w-100 mt-2 font-small" disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Reset Password'}
            </Button>
            {serverMessage && <Alert variant="info" className="mt-3">{serverMessage}</Alert>}
          </Form>
        </Col>
      </Row>
      <Row className="justify-content-center g-2 mt-5 w-100">
        <Col className="text-center">
          <Link to="/login" className="inline-link nav-link">Return to login</Link>
        </Col>
      </Row>
    </>
  );
};

export default PasswordReset;
