import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Button } from 'react-bootstrap';

const GoogleLoginButton = ({ keepMeLoggedIn,canvaState }) => {

  const handleLogin = () => {
  	
    const clientId = '205247422023-7ph08nkcvm6o9lr681u30q6orfjdu8c4.apps.googleusercontent.com';
    const redirectUri = `${window.location.origin}/oauth2-callback`;
	
	 const state = JSON.stringify({
		keepMeLoggedIn: keepMeLoggedIn,
		canvaState: canvaState,  // You can add more data here
	  });
	
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${
      redirectUri
    }&scope=openid%20email%20profile&state=${encodeURIComponent(state)}`;

    window.location.href = googleAuthUrl;
  };

  return (
    <Button
      onClick={handleLogin}
      variant="secondary"
      type="button"
      className="btn-lg w-100 mt-3 d-flex align-items-center justify-content-center font-small"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
        alt="Google Icon"
        width="20rem"
        height="20rem"
        className="me-2"
      />
      Login with Google
    </Button>
  );
};

const GoogleLoginComponent = ({ keepMeLoggedIn,canvaState }) => {
  return (
    <GoogleOAuthProvider clientId="205247422023-7ph08nkcvm6o9lr681u30q6orfjdu8c4.apps.googleusercontent.com">
      <GoogleLoginButton keepMeLoggedIn={keepMeLoggedIn} canvaState={canvaState} />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginComponent;
