import React from 'react';
import { Helmet } from 'react-helmet-async';

const Stats = () => {
  return (
  	<>
		<Helmet>
			<title>Stats – Muzaic</title>
		</Helmet> 
		<div>
		  <h1>Stats</h1>
		  <p>This is the Stats page.</p>
		</div>
	</>
  );
};

export default Stats;
