import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';


const EmptyLayout = ({ children }) => {
  return (
  	<div className="empty-layout-wrapper">
		<Container className="d-flex flex-column justify-content-center align-items-center overflow-auto min-vh-100 py-3">
			 <Row className="justify-content-center g-2 w-100">
				
		  {children}
				<Outlet/>
				
			  </Row>
		</Container>
	</div>
  );
};

export default EmptyLayout;
