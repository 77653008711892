import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { MUZAIC_API_ROOT } from '../config';

const MyMusic = () => {
  const [soundtracks, setSoundtracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [currentTrackId, setCurrentTrackId] = useState(null); // Track the current playing track ID
  const [isPlaying, setIsPlaying] = useState(false); // Track whether the audio is playing or paused
  const audioRef = useRef(null); // Ref for the audio object

  useEffect(() => {
    const fetchSoundtracks = async () => {
      try {
        const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_mymusic.php`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setSoundtracks(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchSoundtracks();

    // Clean up audio on component unmount
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedSoundtracks = React.useMemo(() => {
    if (!sortConfig.key) return soundtracks;

    return [...soundtracks].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [soundtracks, sortConfig]);

  const handlePlayPause = (track) => {

    const file_url = track.file_url;
    const id=track.call_id;

    if (audioRef.current && currentTrackId === id) {
      // If the same track is clicked, toggle play/pause
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      // If a different track is clicked, play the new track
      if (audioRef.current) {
        audioRef.current.pause(); // Pause the current track
      }

      const newAudio = new Audio(file_url);
      audioRef.current = newAudio;
      audioRef.current.play();
      setCurrentTrackId(id);
      setIsPlaying(true);

      // Handle when the track ends
      audioRef.current.onended = () => {
        setIsPlaying(false);
        setCurrentTrackId(null);
      };
    }
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return null;
    if (sortConfig.direction === 'asc') return ' 🔼';
    return ' 🔽';
  };

  return (
    <>
      <Helmet>
        <title>My Music – Muzaic</title>
      </Helmet>
      <div>
        <h1>My Music</h1>
         <p>This is the list of all soundtracks you have generated with your account.</p>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        <div className="table-responsive">
        <Table hover className="bg-white shadow-sm">
          <thead>
            <tr>
              <th onClick={() => requestSort('datetime')} style={{ cursor: 'pointer' }}>
                Date & Time {getSortIndicator('datetime')}
              </th>
              <th onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>
                Name {getSortIndicator('name')}
              </th>
              <th onClick={() => requestSort('duration')} style={{ cursor: 'pointer' }} className="text-end">
                Duration {getSortIndicator('duration')}
              </th>
              <th className="text-center">Play</th>
            </tr>
          </thead>
          <tbody>
            {sortedSoundtracks.map((track) => (
              <tr key={track.call_id}>
                <td>{new Date(track.datetime).toLocaleString()}</td>
                <td>{track.name}</td>
                <td className="text-end">{parseFloat(track.duration).toFixed(2)} s</td>
                <td className="text-center">
                  <i
                    className={`bi ${currentTrackId === track.call_id && isPlaying ? 'bi-pause-fill' : 'bi-play-fill'}`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handlePlayPause(track)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
      </div>
    </>
  );
};

export default MyMusic;
