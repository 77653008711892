import React, { useState, useEffect } from 'react';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useLocation} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { MUZAIC_API_ROOT, STRIPE_PUBLIC_KEY } from '../config';
import ProductList from '../components/ProductList.tsx';


// Load the publishable key from your environment
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CanvaPurchase = () => {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [error, setError] = useState(null);

const location = useLocation();

  

  return (
    <>
      <Helmet>
        <title>Purchase – Muzaic</title>
      </Helmet>
      
      <h1 className="text-white text-center mb-5 mt-5">Upgrade AI Music</h1>
      <ProductList />
      <div className="text-white text-center mt-8">
      © Muzaic 2024 <br></br><a className="text-decoration-none" href="https://muzaic.ai/terms-of-use" target="_blank"> Terms of use</a>
      </div>
      
    </>
  );
};

export default CanvaPurchase;
